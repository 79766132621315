// widok potwierdzenia zamówienia

import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import classnames from 'classnames';
import { ChevronLeft } from 'react-bootstrap-icons';

import { useGetCartThankYouInfo, usePostOnlinePaymentInitialize } from 'api';
import { Container, Link } from 'components/controls';

import styles from 'theme/pages/Checkout/components/Confirmation/Confirmation.module.scss';

// typ danych wejściowych
interface IProps {
  cartId: number;
}

const Confirmation: FC<IProps> = ({ cartId }) => {
  // pobranie danych informacyjnych do wyświetlenia
  const { data } = useGetCartThankYouInfo(cartId);

  //inicjalizacja metody płatności
  const { mutate: initializePayment } = usePostOnlinePaymentInitialize({
    onSuccess: ({ data }) => {
      if (data.redirect_type === 'REDIRECT_URL') {
        window.location.replace(data.redirect_payload);
      }
    }
  });

  const handleButtonClick = () => {
    if (data?.payment_action_enable) {
      initializePayment({
        context: 'ORDER_CREATED',
        entity_type: 'ORDER',
        entity_id: data?.order_id || 0
      });

      return;
    }

    window.location.href = data?.button_url || '/';
  };

  return (
    <div
      className={classnames(
        styles.componentWrapper,
        'StylePath-Pages-Checkout-Components-Confirmation'
      )}>
      <Container>
        <div className={styles.backIcon}>
          <Link to="/">
            <ChevronLeft /> <Trans>Wróć na stronę główną</Trans>
          </Link>
        </div>
        <div className={styles.content}>
          <div>
            <div dangerouslySetInnerHTML={{ __html: data?.title || '' }} />
            <div className={styles.orderNo}>{data?.order_number}</div>
            <div
              className={styles.description}
              dangerouslySetInnerHTML={{ __html: data?.description_html || '' }}
            />
            {!data?.payment_action_enable && (
              <div
                className={styles.details}
                dangerouslySetInnerHTML={{ __html: data?.account_banks_information || '' }}
              />
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Confirmation;
