// listy płatności

import React, { FC } from 'react';
import classnames from 'classnames';
import { Trans } from 'react-i18next';

import { ISettlementsSummary } from 'api/types';
import { ClockIcon, AlertIcon } from 'assets/icons';

import styles from 'theme/pages/Dashboard/Dashboard.module.scss';

// typ danych wejściowych
interface IProps {
  settlements?: ISettlementsSummary['settlements'];
}

const Payments: FC<IProps> = ({ settlements }) => {
  return (
    <div className={classnames(styles.payments, 'StylePath-Pages-Dashboard-Components-Payments')}>
      <div className={styles.tile}>
        <div className={styles.title}>
          <ClockIcon />
          <Trans>Nadchodzące płatności</Trans>
        </div>
        <div className={styles.row}>
          <span>
            <Trans>Ilość faktur</Trans>
          </span>
          <span>
            <Trans>Suma</Trans>
          </span>
        </div>
        <div className={styles.row}>
          <div>{settlements?.upcoming.pln.count}</div>
          <div className={styles.price}>
            {settlements?.upcoming.pln.total_formatted.replace('.', ',')}{' '}
            {settlements?.upcoming.pln.currency}{' '}
            <span className={styles.brutto}>
              <Trans>brutto</Trans>
            </span>
          </div>
        </div>
      </div>
      <div
        className={classnames(styles.tile, {
          [styles.noOverdue]: !settlements?.expired.pln.count
        })}>
        {settlements?.expired.pln.count ? (
          <>
            <div className={styles.title}>
              <AlertIcon />
              <Trans>Po terminie</Trans>
            </div>
            <div className={styles.row}>
              <span>
                <Trans>Ilość faktur</Trans>
              </span>
              <span>
                <Trans>Suma</Trans>
              </span>
            </div>
            <div className={styles.row}>
              <div>{settlements?.expired.pln.count}</div>
              <div className={styles.price}>
                {settlements?.expired.pln.total_formatted.replace('.', ',')}{' '}
                {settlements?.expired.pln.currency}{' '}
                <span className={styles.brutto}>
                  <Trans>brutto</Trans>
                </span>
              </div>
            </div>
          </>
        ) : (
          <Trans>Brak przeterminowanych faktur.</Trans>
        )}
      </div>
    </div>
  );
};

export default Payments;
