// sekcja z metodami płatności

import React, { FC, useState, useEffect } from 'react';
import classnames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';

import { useGetCartPaymentsMethods } from 'api';

import { ICartMainData, ICartMainDataUpdateRequest } from 'api/types';
import { Radio, Modal, Input, Button } from 'components/controls';

import styles from 'theme/pages/Checkout/components/Payment/Payment.module.scss';

// typ danych wejściowych
interface IProps {
  cartId: number;
  paymentMethod: ICartMainData['payment_method'];
  cashOnDelivery: ICartMainData['cash_on_delivery_value'];
  updateCartMainData: (data: Partial<ICartMainDataUpdateRequest>) => void;
  currency?: string;
  refetchDeliveryMethodsData: () => void;
  isLoading: boolean;
}

const Payment: FC<IProps> = ({
  cartId,
  paymentMethod,
  cashOnDelivery,
  updateCartMainData,
  currency,
  refetchDeliveryMethodsData,
  isLoading
}) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const [cashOnDeliveryInputValue, setCashOnDeliveryInputValue] = useState('');

  // ustawianie domyślnej wartości kwoty za pobraniem
  useEffect(() => {
    if (cashOnDelivery) {
      setCashOnDeliveryInputValue(cashOnDelivery.toString());
    }
  }, [cashOnDelivery]);

  // pobranie listy metod płatności
  const { data: cartPaymentsMethodsData } = useGetCartPaymentsMethods(cartId, {
    page: 1,
    limit: 999
  });

  const handlePaymentMethodClick = async (id: number, cashOnDeliveryEditable: boolean) => {
    await updateCartMainData({ payment_method_id: id });
    refetchDeliveryMethodsData();

    if (!paymentMethod?.id && cashOnDeliveryEditable) {
      setIsOpen(true);
    }
  };

  return (
    <div
      className={classnames(
        styles.componentWrapper,
        'StylePath-Pages-Checkout-Components-Payment'
      )}>
      <h2>
        <Trans>Płatność</Trans>
      </h2>
      <div className={styles.paymentsWrapper}>
        {cartPaymentsMethodsData?.items.map((item) => (
          <div
            onClick={() => handlePaymentMethodClick(item.id, item.cash_on_delivery_editable)}
            key={item.id}
            className={classnames(styles.item, {
              [styles.active]: item.id === paymentMethod?.id,
              [styles.isLoading]: isLoading
            })}>
            <div className={classnames(styles.radio)}>
              <Radio checked={item.id === paymentMethod?.id} />
            </div>
            <div className={styles.name}>
              {item.name}
              {!!item.cash_on_delivery_editable && item.id === paymentMethod?.id && (
                <div className={styles.cashDeliveryValue}>
                  {cashOnDelivery} {currency}
                </div>
              )}
            </div>
            <div className={styles.iconWrapper}>
              {item.image && <img className={styles.icon} src={item.image} alt="icon" />}
            </div>
            <div className={styles.content}>
              {!!item.price_gross && (
                <div className={styles.commission}>
                  <span>+{item.price_formatted_to_show}</span>
                </div>
              )}
              {!!item.cash_on_delivery_editable && item.id === paymentMethod?.id && (
                <div className={styles.cashDelivery}>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsOpen(true);
                    }}>
                    <Trans>Zmień</Trans>
                  </button>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      {isOpen && (
        <Modal title={t('Kwota pobrania')} onClose={() => setIsOpen(false)}>
          <div>
            <Input
              value={cashOnDeliveryInputValue}
              onChange={(value) => {
                setCashOnDeliveryInputValue(value);
              }}
            />
          </div>
          <div className={styles.buttonWrapper}>
            <Button
              onClick={async () => {
                if (cashOnDeliveryInputValue) {
                  await updateCartMainData({
                    cash_on_delivery_value: parseFloat(cashOnDeliveryInputValue.replace(',', '.'))
                  });

                  setIsOpen(false);
                }
              }}>
              <Trans>Zapisz</Trans>
            </Button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Payment;
