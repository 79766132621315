// widok pozycji w wersji default

import React, { ReactElement } from 'react';
import { Trans } from 'react-i18next';

import { IColumn } from 'components/controls/Table';
import { IProductVersion, IUnit, IPosition, IProduct } from 'api/types';

import SizingTableQty from './SizingTableQty';

import styles from 'theme/pages/Product/components/SizingTable/SizingTable.module.scss';

export const getSimpleColumns = ({
  unit,
  positions,
  changeProductPositions
}: {
  renderCell: (arg: number) => ReactElement;
  isMobile: boolean;
  unit?: IUnit;
  product: IProduct;
  positions: IPosition[];
  changeProductPositions: (arg: IPosition) => void;
  onClickSizingTable: () => void;
}): IColumn<IProductVersion>[] => [
  {
    title: <Trans>Rozmiar</Trans>,
    key: 'size',
    align: 'center',
    renderCell: (item) => <div className={styles.cell}>{item.size}</div>
  },
  {
    title: <Trans>Kontraktacja</Trans>,
    key: 'qty',
    align: 'center',
    renderCell: (item) =>
      unit && (
        <SizingTableQty
          productId={item.product_id}
          unitId={unit.unit_id}
          onChangeQty={changeProductPositions}
          isNoSelected={!positions.length}
          fullWidth
        />
      )
  }
];

export default getSimpleColumns;
