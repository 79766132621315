// główny layout

import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { usePostNewsletterRegister } from 'api';
import { Input, Button } from 'components/controls';

import styles from 'theme/components/containers/Newsletter/Newsletter.module.scss';

const Newsletter = () => {
  const { t } = useTranslation();

  // ustawienie wartości w polu input
  const [email, setEmail] = useState('');

  // zapisanie emaila do newslettera
  const { mutate: newsletterRegister } = usePostNewsletterRegister({
    onSuccess: () => {
      setEmail('');
    }
  });
  return (
    <div className={classnames(styles.componentWrapper, 'StylePath-Pages-Home-Newsletter')}>
      <div className={styles.newsletterTop}>
        <h2>BĄDŹ NA BIEŻĄCO</h2>
        <h3>Zapisz się do newslettera</h3>
        <div className={styles.text}>
          <div>Nie przegap nowych kolekcji, promocji oraz przecen. Bądź z nami w</div>{' '}
          <div>kontakcie i śledź świat Momenti Per Me.</div>
        </div>
        <div className={styles.newsletter}>
          <Input placeholder={t('Wpisz swój e-mail...')} onChange={(value) => setEmail(value)} />
          <Button onClick={() => newsletterRegister({ email })}>
            <Trans>Subskrybuj</Trans>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
