// widok pozycji w wersji view

import React from 'react';
import { Trans } from 'react-i18next';
import classnames from 'classnames';

import { ICartPositionListItem } from 'api/types';
import { IColumn } from 'components/controls/Table';
import { Link } from 'components/controls';

import styles from 'theme/components/containers/CartPositions/CartPositions.module.scss';

export const getViewColumns = ({
  isMobile
}: {
  isMobile: boolean;
}): IColumn<ICartPositionListItem>[] => [
  {
    title: '',
    key: 'product-image',
    align: 'left',
    renderCell: (item) => (
      <div className={styles.productThumb}>
        <Link to={`/${item.url_link}`}>
          <img src={item.image[0]?.thumb} alt={item.name} />
        </Link>
      </div>
    ),
    width: 78
  },
  {
    title: '',
    dataIndex: 'name',
    align: 'center',
    renderCell: (item) => (
      <div className={styles.productInfo}>
        <div>
          <Link to={`/${item.url_link}`}>
            <span className={styles.productName}>{item.name}</span>
          </Link>
          <span className={styles.productSize}>
            <Trans>Rozmiar</Trans>: <span>{item.size}</span>
          </span>
          {item.color && (
            <span className={classnames(styles.productSize, styles.productColor)}>
              <Trans>Kolor</Trans>: <span>{item.color}</span>
            </span>
          )}
        </div>
        <div className={styles.quantityCellWrapper}>Ilość: 2</div>
      </div>
    )
  },
  {
    title: '',
    dataIndex: 'total_price_net',
    align: 'center',
    renderCell: (item) => (
      <div
        className={classnames(
          styles.productTotalPrice,
          item.total_old_price_net_formatted && styles.discount
        )}>
        <div className={classnames(styles.productPriceWrapper, { [styles.isSummary]: true })}>
          <span className={styles.priceLabel}>
            <Trans>Wartość</Trans>
          </span>
          :&nbsp;
          {item.total_old_price_net_formatted && (
            <div className={styles.oldPrice}>
              {item.total_old_price_net_formatted.replace('.', ',')} {item.currency}{' '}
              {!isMobile && <Trans>netto</Trans>}
            </div>
          )}
          <div>
            &nbsp;{item.total_price_net_formatted.replace('.', ',')} {item.currency}{' '}
            {!isMobile && <Trans>netto</Trans>}
          </div>
        </div>
      </div>
    )
  }
];
