// główny layout - środkowa belka nagłówka

import React, { FC, Dispatch, SetStateAction, useMemo, useState } from 'react';
import classnames from 'classnames';
import Drawer from '@mui/material/Drawer';
import Slider, { CustomArrowProps } from 'react-slick';
import { filter } from 'lodash';

import { useDispatch, reduxActions } from 'store';
import { Languages, CartsButton, LoginButton, Search } from 'components/containers';
import { Link } from 'components/controls';
import {
  HamburgerIcon,
  SearchIcon,
  HamburgerWhiteIcon,
  SearchWhiteIcon,
  HamburgerMobileIcon
} from 'assets/icons';
import { useRWD } from 'hooks';
import { useGetLayoutCategories } from 'api';

import Categories from '../Categories';
import { SliderArrow } from 'assets/icons';

import logo from 'assets/images/logo.png';
import logoWhite from 'assets/images/logo-white.png';

import styles from 'theme/components/layouts/MainLayout/components/HeaderMain/HeaderMain.module.scss';

// typ danych wejściowych
interface IProps {
  simpleHeader: boolean;
  isHomePage?: boolean;
  isProductListPage?: boolean;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const HeaderMain: FC<IProps> = ({ simpleHeader, isProductListPage, isHomePage, open, setOpen }) => {
  const dispatch = useDispatch();
  const { isMobile } = useRWD();

  const [isSearchVisible, setIsSearchVisible] = useState(false);

  // pobranie listy kategorii
  const { data: categoriesData } = useGetLayoutCategories({
    page: 1,
    limit: 999
  });

  const NextArrow = ({ ...props }: CustomArrowProps) => (
    <button {...props} className={classnames('slick-next slick-arrow', styles.nextArrow)}>
      <SliderArrow />
    </button>
  );

  const categoriesList = useMemo(() => {
    return categoriesData?.items.filter((o) => !!o.categories_list?.length);
  }, [categoriesData, isMobile]);

  const mainMenuPositions = useMemo(() => {
    return categoriesData?.items.filter((o) => !o.categories_list?.length);
  }, [categoriesData, isMobile]);

  const images = useMemo(() => {
    return categoriesData?.items
      .find((o) => !!o.categories_list?.length)
      ?.categories_list?.map((category) => {
        if (category.image) {
          return {
            image: category.image,
            image_redirect_url: category.image_redirect_url,
            name: category.label_category_from_cms
          };
        }
      });
  }, [categoriesData, isMobile]);

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    swipeToSlide: true,
    nextArrow: <NextArrow />
  };

  const renderMainMenuPositions = () =>
    mainMenuPositions?.map((item, i) => (
      <span key={i}>
        <Link to={`/${item.url_link}` || ''}>{item.label}</Link>
      </span>
    ));

  const headerHeight =
    (document.getElementById('header')?.getBoundingClientRect().height || 0) +
    (document.getElementById('top-bar')?.getBoundingClientRect().height || 0);

  const renderSlides = () =>
    filter(images)?.map((image, index) => (
      <a href={image?.image_redirect_url} key={index} className={styles.slide}>
        {image?.image && <img src={image?.image} />}
        <span>{image?.name}</span>
      </a>
    ));

  return (
    <div
      className={classnames(
        styles.componentWrapper,
        {
          [styles.simpleHeader]: simpleHeader && isProductListPage,
          [styles.isHomePage]: !!isHomePage,
          [styles.open]: !!open
        },
        'StylePath-Components-Layouts-MainLayout-Components-HeaderMain'
      )}>
      <div className={styles.main}>
        <div className={styles.leftActions}>
          <div>
            <button onClick={() => setOpen(!open)}>
              {isHomePage ? (
                <HamburgerWhiteIcon />
              ) : isMobile ? (
                <HamburgerMobileIcon />
              ) : (
                <HamburgerIcon />
              )}
            </button>
            {isMobile && (
              <>
                {isHomePage ? (
                  <SearchWhiteIcon onClick={() => setIsSearchVisible(!isSearchVisible)} />
                ) : (
                  <SearchIcon onClick={() => setIsSearchVisible(!isSearchVisible)} />
                )}
                <div className={classnames(styles.b2bIcon, { [styles.black]: !isHomePage })}>
                  B2B
                </div>
              </>
            )}
            {!isMobile && renderMainMenuPositions()}
          </div>
          <div>{open && <Languages />}</div>
        </div>
        <Link
          to="/"
          onClick={() => dispatch(reduxActions.setSearchKeyword(''))}
          className={styles.logo}>
          <img src={isHomePage ? logoWhite : logo} />
        </Link>
        <div className={styles.rightActions}>
          {!isMobile && <Search isSearchVisible={isSearchVisible} isHomePage={isHomePage} />}

          {isHomePage ? (
            <>
              {!isMobile && (
                <SearchWhiteIcon onClick={() => setIsSearchVisible(!isSearchVisible)} />
              )}
              <LoginButton isHomePage={isHomePage} />
              <CartsButton isHomePage={isHomePage} />
              {!isMobile && <div className={styles.b2bIcon}>B2B</div>}
            </>
          ) : (
            <>
              {!isMobile && <SearchIcon onClick={() => setIsSearchVisible(!isSearchVisible)} />}
              <LoginButton isHomePage={isHomePage} />
              <CartsButton isHomePage={isHomePage} />
              {!isMobile && <div className={classnames(styles.b2bIcon, styles.black)}>B2B</div>}
            </>
          )}
        </div>
      </div>
      {isMobile && isSearchVisible && (
        <Search isSearchVisible={isSearchVisible} isHomePage={isHomePage} />
      )}
      <Drawer
        SlideProps={{ style: { width: '100%', top: headerHeight } }}
        BackdropProps={{ style: { top: 197 } }}
        anchor="top"
        open={open}
        onClose={() => setOpen(false)}>
        <div className={styles.drawerInner}>
          <div className={styles.categories}>
            <Categories
              mainMenuPositions={isMobile ? mainMenuPositions : []}
              categories={categoriesList}
              onCategoryClick={() => {
                setOpen(false);
              }}
            />
          </div>
          {!isMobile ? (
            <div className={styles.slider}>
              <Slider {...settings}>{renderSlides()}</Slider>
            </div>
          ) : (
            <div className={styles.sliderMobile}>{renderSlides()}</div>
          )}
        </div>
      </Drawer>
    </div>
  );
};

export default HeaderMain;
