// stopka głównego layoutu

import React, { FC } from 'react';
import classnames from 'classnames';

import { HtmlBlock } from 'components/containers/HtmlBlock';

import styles from 'theme/components/layouts/MainLayout/components/Footer/Footer.module.scss';

// typ danych wejściowych
interface IProps {
  isHomePage?: boolean;
}

const Footer: FC<IProps> = ({ isHomePage }) => {
  const renderSocials = () => <HtmlBlock sectionId="FOOTER" articleId="FOOTER_ICONS" />;

  return (
    <div
      className={classnames(
        styles.componentWrapper,
        { [styles.isHomePage]: !!isHomePage },
        'StylePath-Components-Layouts-MainLayout-Components-Footer'
      )}>
      <HtmlBlock sectionId="FOOTER" articleId="FOOTER_DESKTOP" />
      <div className={styles.socials}>{renderSocials()}</div>
    </div>
  );
};

export default Footer;
