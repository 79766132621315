// lista dokumentów

import React, { useState, useEffect, useMemo } from 'react';
import classnames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { format, parse } from 'date-fns';
import mapValues from 'lodash/mapValues';
import keyBy from 'lodash/keyBy';

import { reduxActions, useDispatch } from 'store';
import { useGetDocumentsVmp, useGetDocumentsInformationVmp } from 'api';
import { useRWD } from 'hooks';
import { IDocumentListItemVmp, IDocumentsVmpRequest } from 'api/types';
import { Table, SearchInput, DateRangePicker, Checkbox, Collapse } from 'components/controls';
import { Payments } from 'components/containers';
import { ISorter } from 'components/controls/Table';

import { getSimpleColumns } from './columnsSimple';
import { getExtendedColumns } from './columnsExtended';
import { getCorrectionColumns } from './columnsCorrection';

import styles from 'theme/pages/Documents/Documents.module.scss';

const Documents = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isMobile } = useRWD();

  // zakładki
  const tabs: {
    name: string;
    key: 'INVOICE' | 'PROFORMA' | 'INVOICECORECTION' | 'WZ';
  }[] = [
    {
      name: t('Proforma'),
      key: 'PROFORMA'
    },
    {
      name: t('Faktury'),
      key: 'INVOICE'
    },
    {
      name: t('Korekty'),
      key: 'INVOICECORECTION'
    }
  ];

  // parametry zapytania o listę dokumentów
  const [queryParams, setQueryParams] = useState<IDocumentsVmpRequest>({
    page: 1,
    limit: 20,
    documentType: 'PROFORMA',
    sortMethod: ''
  });

  const [sorter, setSorter] = useState<ISorter<IDocumentListItemVmp>>({
    by: 'create_datetime',
    direction: 'asc'
  });

  // aktualnie wybrany tab
  const currentTab = tabs.find((tab) => tab.key === queryParams.documentType)?.key || 'PROFORMA';

  const [sortByDocumentType, setSortByDocumentType] = useState(
    mapValues(keyBy(tabs, 'key'), () => ({
      ...sorter
    }))
  );

  // pobranie informacji o płatności
  const { data: documentsInformation } = useGetDocumentsInformationVmp();

  // pobranie listy dokumentów
  const { data: documentsDataVmp } = useGetDocumentsVmp({
    ...queryParams,
    sortMethod: `${sortByDocumentType[currentTab].by}_${sortByDocumentType[
      currentTab
    ].direction.toUpperCase()}`
  });

  // ustawieni breadcrumbs'ów
  useEffect(() => {
    dispatch(
      reduxActions.setBreadcrumbs([
        { name: t('Dashboard'), path: '/dashboard' },
        { name: t('Moje dokumenty') }
      ])
    );
  }, []);

  // czy są wybrane filtry
  const filtersSelected =
    !!queryParams.dateFrom ||
    !!queryParams.dateTo ||
    !!queryParams.searchKeyword ||
    !!queryParams.expiredPayments;

  // wybranie wariantu tabelki
  const columns = useMemo(() => {
    if (currentTab === 'INVOICE') {
      return getExtendedColumns({ documentsInformation });
    }

    if (currentTab === 'INVOICECORECTION') {
      return getCorrectionColumns({ documentsInformation });
    }

    return getSimpleColumns({ documentsInformation });
  }, [currentTab]);

  const renderTable = () => {
    return (
      <div className={styles.tableWrapper}>
        <Table<IDocumentListItemVmp>
          columns={columns}
          dataSource={documentsDataVmp?.items || []}
          isDocuments={true}
          rowKey="id"
          sorter={sortByDocumentType[currentTab]}
          onSortChange={(sorter) => {
            setSorter(sorter);
            setSortByDocumentType({ ...sortByDocumentType, [currentTab as string]: sorter });
          }}
          pagination={{
            page: queryParams.page || 1,
            pagesCount: documentsDataVmp?.total_pages || 1,
            onChange: (page) => setQueryParams((prevState) => ({ ...prevState, page }))
          }}
        />
      </div>
    );
  };
  return (
    <div className={classnames(styles.componentWrapper, 'StylePath-Pages-Documents')}>
      <Payments />

      <div className={styles.filtersWrapper}>
        <div className={styles.search}>
          <SearchInput
            value={queryParams.searchKeyword}
            onChange={(value) =>
              setQueryParams((prevState) => ({ ...prevState, searchKeyword: value }))
            }
            placeholder={t(currentTab === 'PROFORMA' ? 'Wpisz numer zamówienia' : 'Szukaj')}
          />
        </div>
        <div className={styles.date}>
          <DateRangePicker
            dateRange={{
              from: queryParams.dateFrom
                ? format(parse(queryParams.dateFrom, 'dd-MM-yyyy', new Date()), 'yyyy-MM-dd')
                : undefined,
              to: queryParams.dateTo
                ? format(parse(queryParams.dateTo, 'dd-MM-yyyy', new Date()), 'yyyy-MM-dd')
                : undefined
            }}
            onChange={(range) =>
              setQueryParams((prevState) => ({
                ...prevState,
                dateFrom: format(new Date(range.from), 'dd-MM-yyyy'),
                dateTo: format(new Date(range.to), 'dd-MM-yyyy')
              }))
            }
          />
        </div>
        <div
          className={classnames(
            styles.filtersInner,
            currentTab === 'INVOICE' && styles.hasCheckbox
          )}>
          <div>
            {filtersSelected && (
              <button
                className={styles.clearFilters}
                onClick={() =>
                  setQueryParams((prevState) => ({
                    page: 1,
                    limit: 20,
                    documentType: prevState.documentType
                  }))
                }>
                x <Trans>Wyczyść filtry</Trans>
              </button>
            )}
          </div>

          {currentTab === 'INVOICE' && (
            <div>
              <Checkbox
                checked={!!queryParams.expiredPayments}
                onClick={() =>
                  setQueryParams((prevState) => ({
                    ...prevState,
                    expiredPayments: !prevState.expiredPayments
                  }))
                }
              />
              <Trans>Pokaż tylko płatności po terminie</Trans>
            </div>
          )}
        </div>
      </div>
      {isMobile && (
        <div className={styles.mobileTabsWrapper}>
          {tabs.map((item) => (
            <Collapse
              key={item.key}
              title={
                <div
                  className={classnames('', {
                    [styles.active]: queryParams.documentType === item.key
                  })}
                  onClick={() =>
                    setQueryParams(() => ({
                      documentType: item.key,
                      page: 1
                    }))
                  }>
                  <div className={styles.summaryName}>
                    {item.name}{' '}
                    {queryParams.documentType === item.key && `(${documentsDataVmp?.total_count})`}
                  </div>
                </div>
              }>
              {renderTable()}
            </Collapse>
          ))}
        </div>
      )}
      {!isMobile && (
        <div className={styles.tabsHeader}>
          {tabs.map((item) => (
            <div
              key={item.key}
              className={classnames(styles.tabsBox, {
                [styles.active]: queryParams.documentType === item.key
              })}
              onClick={() => setQueryParams(() => ({ documentType: item.key, page: 1 }))}>
              <div className={styles.summaryName}>
                {item.name}{' '}
                {queryParams.documentType === item.key && `(${documentsDataVmp?.total_count})`}
              </div>
            </div>
          ))}
        </div>
      )}

      {!isMobile && renderTable()}
    </div>
  );
};

export default Documents;
