import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import classnames from 'classnames';
import { format } from 'date-fns';
import { ChevronDoubleLeft } from 'react-bootstrap-icons';

import { useRWD } from 'hooks';
import { IOrderVmp } from 'api/types';
import { Link } from 'components/controls';
import { OrderSwitch } from 'components/containers';
import { DownloadSection } from '../OrderSummary/components';

import { CheckIcon, BiDownloadIcon } from 'assets/icons';

import styles from 'theme/pages/Order/components/OrderSummary/OrderSummary.module.scss';

// typ danych wejściowych
interface IProps {
  orderData: IOrderVmp;
  isEditingMode: boolean;
  refetchOrderData: () => void;
}

const Header: FC<IProps> = ({ orderData, isEditingMode, refetchOrderData }) => {
  const { isMobile } = useRWD();

  return (
    <div
      className={classnames(
        styles.wrapperComponent,
        'StylePath-Pages-Order-components-OrderHeader'
      )}>
      <div className={styles.topActions}>
        <div className={styles.backIcon}>
          <Link to="/orders">
            <ChevronDoubleLeft /> <Trans>Wróć na stronę główną</Trans>
          </Link>
        </div>
        {!isMobile && (
          <div className={styles.downloadLabel}>
            <BiDownloadIcon />
            <Trans>Pobierz</Trans>
          </div>
        )}
      </div>
      <div className={styles.titleWrapper}>
        <div>
          <div className={styles.title}>
            <span>
              <Trans>Zamówienie</Trans>: {orderData?.id}
            </span>{' '}
            {orderData.dropshipping && (
              <span>
                <Trans>Dropshipping</Trans>
                <CheckIcon />
              </span>
            )}
          </div>
        </div>
        {!isMobile && (
          <div className={styles.downloadSection}>
            <DownloadSection orderData={orderData} />
          </div>
        )}
      </div>
      <div className={styles.statusWrapper}>
        <div>
          <div>
            <Trans>Data zamówienia</Trans>: {format(new Date(orderData.create_date), 'dd.MM.yyyy')}
          </div>
          <div>
            <Trans>Status zamówienia</Trans>:{' '}
            <span style={{ color: orderData.status.color }}>{orderData.status.label}</span>
          </div>
        </div>
        <div className={styles.hold}>
          {orderData.is_blocked ? <Trans>Zablokowane</Trans> : <Trans>Odblokowane</Trans>}

          <OrderSwitch
            orderId={orderData.id}
            isBlocked={orderData.is_blocked}
            refetch={refetchOrderData}
            isEditingMode={isEditingMode}
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
