// lista kategorii

import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Grid } from '@mui/material';

import { useRWD } from 'hooks';
import { useGetProductsCategories } from 'api';
import { ICategoryListItem, IProductsCategoriesRequest, IProductsRequest } from 'api/types';
import { Collapse, Loader } from 'components/controls';

import styles from 'theme/pages/Products/components/Categories/Categories.module.scss';

// typ danych wejściowych
interface Props {
  onCategoryClick: (category: ICategoryListItem) => void;
  columnsCount?: number;
  chosenCategoryId?: number;
  searchKeyword?: string;
  productsQueryParams: IProductsRequest;
}

const Categories: FC<Props> = ({
  onCategoryClick,
  columnsCount = 1,
  chosenCategoryId,
  productsQueryParams
}) => {
  const { t } = useTranslation();
  const { isMobile } = useRWD();

  // parametry zapytania do api
  const [categoriesQuery, setCategoriesQuery] = useState<IProductsCategoriesRequest>({
    ...productsQueryParams,
    page: 1,
    limit: 999
  });

  // pobranie listy kategorii
  const { data: categoriesData, isLoading: isCategoriesLoading } = useGetProductsCategories(
    categoriesQuery,
    { keepPreviousData: true }
  );

  // aktualizacja parametrów zapytania do api przy zmianie parametrów zapytaqnia o produkty
  useEffect(() => {
    setCategoriesQuery({
      ...productsQueryParams,
      page: 1,
      limit: 999
    });
  }, [productsQueryParams]);

  // funkcja renderująca drzewo kategorii
  const renderCategoryTree = (category: ICategoryListItem) => {
    // funkcja rekurencyjna renderująca  listę wraz z rozwiniętymi podkategoriami
    const renderTree = (categories: ICategoryListItem[] = [], level = 0) =>
      categories.map((category) => (
        <div
          key={category.id}
          className={classnames(styles.category, { [styles.main]: level === 0 })}>
          <button
            onClick={() => onCategoryClick(category)}
            className={classnames({ [styles.chosen]: category.id === chosenCategoryId })}>
            <div>
              {category.name} <i className={styles.counter}>({category.products_count})</i>
            </div>
          </button>
        </div>
      ));

    return renderTree([category]);
  };

  if (categoriesData?.items.length) {
    return (
      <div
        className={classnames(
          styles.wrapperComponent,
          'StylePath-Pages-Products-components-Categories'
        )}>
        {isCategoriesLoading && <Loader />}
        <Collapse title={t('Kategorie')}>
          <Grid container>
            <div className={styles.content}>
              {categoriesData?.items.map((category) => (
                <Grid item key={category.id} sm={Math.floor(12 / columnsCount)}>
                  {renderCategoryTree(category)}
                </Grid>
              ))}
            </div>
          </Grid>
        </Collapse>
      </div>
    );
  }

  return null;
};

export default Categories;
