// lista płatności

// TODO - example of refactored mobile
import React, { useMemo, useState, useEffect } from 'react';
import classnames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { format, parseISO, parse } from 'date-fns';

import { useRWD } from 'hooks';
import { useGetSettlementsVmp, useGetSettlementsTypeVmp } from 'api';
import { reduxActions, useDispatch } from 'store';
import { ISettlementVmpRequest, ISettlementVmp } from 'api/types';
import { SearchInput, DateRangePicker, Select } from 'components/controls';
import Table, { IColumn, ISorter } from 'components/controls/Table';

import { Payments } from 'components/containers';

import styles from 'theme/pages/Finances/Finances.module.scss';

interface IStatus {
  type: string;
}

const FinancesPayments = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isMobile } = useRWD();

  // domyślne parametry zapytania do API o listę płatności
  const defaultQueryParams = {
    page: 1,
    limit: 20,
    sortMethod: ''
  };

  // parametry zapytania do API o listę płatności
  const [queryParams, setQueryParams] = useState<ISettlementVmpRequest>(defaultQueryParams);

  const [sorter, setSorter] = useState<ISorter<ISettlementVmp>>({
    by: 'type',
    direction: 'asc'
  });

  // pobieranie listy płatności
  const { data: settlements } = useGetSettlementsVmp(queryParams);

  //pobieranie typów płatności
  const { data: settlementsType } = useGetSettlementsTypeVmp();

  // ustawienie breadcrumbs'ów na starcie strony
  useEffect(() => {
    dispatch(
      reduxActions.setBreadcrumbs([
        { name: t('Moje konto'), path: 'Dashboard' },
        { name: t('Finanse') }
      ])
    );
  }, []);

  // czy są wybrane filtry
  const filtersSelected =
    !!queryParams.dateFrom ||
    !!queryParams.dateTo ||
    !!queryParams.sortMethod ||
    !!queryParams.searchKeyword ||
    !!queryParams.type;

  const columns: IColumn<ISettlementVmp>[] = useMemo(
    () => [
      {
        title: isMobile ? <Trans>Data</Trans> : <Trans>Data utworzenia</Trans>,
        key: 'booking_date',
        align: 'left',
        renderCell: (item) => format(parseISO(item.booking_date), 'dd-MM-yyyy')
      },
      {
        title: <Trans>Typ</Trans>,
        dataIndex: 'type',
        align: 'center',
        renderCell: (item) => <>{item.type}</>
      },
      {
        title: <Trans>Operacja</Trans>,
        dataIndex: 'description',
        align: 'center'
      },
      {
        title: <Trans>Kwota brutto</Trans>,
        key: 'amount_formatted',
        align: 'center',
        renderCell: (item) => (
          <span className={styles.value}>
            {item.amount_formatted.replace('.', ',')} {item.currency}
          </span>
        )
      },
      {
        title: <Trans>Saldo obecne</Trans>,
        key: 'balance_formatted',
        align: 'center',
        renderCell: (item) => (
          <span className={styles.value}>
            {item.balance_formatted.replace('.', ',')} {item.currency}
          </span>
        )
      },
      {
        title: <Trans>Saldo przyszłe</Trans>,
        key: 'balance_future_formatted',
        align: 'right',
        renderCell: (item) => (
          <span className={styles.value}>
            {item.balance_future_formatted.replace('.', ',')} {item.currency}
          </span>
        )
      }
    ],
    []
  );

  const renderTypeSelect = () => {
    return (
      <Select
        placeholder={t('Wszystkie typy')}
        options={
          settlementsType?.items.map((settlement) => ({
            value: settlement.type,
            label: settlement.translation,
            item: { type: settlement.type }
          })) || []
        }
        onChange={(item: IStatus | null) =>
          item &&
          setQueryParams((prevState) => ({
            ...prevState,
            type: item.type
          }))
        }
        value={queryParams.type}
      />
    );
  };

  return (
    <div className={classnames(styles.wrapperComponent, 'StylePath-Pages-Finances')}>
      <Payments />
      <div className={styles.filtersWrapper}>
        <div className={styles.filtersInner}>
          <SearchInput
            placeholder={t('Szukaj')}
            value={queryParams.searchKeyword}
            onChange={(value) =>
              setQueryParams((prevState) => ({ ...prevState, searchKeyword: value, page: 1 }))
            }
          />
          <DateRangePicker
            dateRange={{
              from: queryParams.dateFrom
                ? format(parse(queryParams.dateFrom, 'dd-MM-yyyy', new Date()), 'yyyy-MM-dd')
                : undefined,
              to: queryParams.dateTo
                ? format(parse(queryParams.dateTo, 'dd-MM-yyyy', new Date()), 'yyyy-MM-dd')
                : undefined
            }}
            onChange={(range) =>
              setQueryParams((prevState) => ({
                ...prevState,
                date_from: format(new Date(range.from), 'dd-MM-yyyy'),
                date_to: format(new Date(range.to), 'dd-MM-yyyy')
              }))
            }
          />
          {filtersSelected && (
            <button
              className={styles.clearFilters}
              onClick={() =>
                setQueryParams(() => ({
                  page: 1,
                  limit: 20
                }))
              }>
              x <Trans>Wyczyść filtry</Trans>
            </button>
          )}
          {isMobile && renderTypeSelect()}
        </div>
        {!isMobile && renderTypeSelect()}
      </div>
      <Table
        isFullTableOnMobile={true}
        columns={columns}
        dataSource={settlements?.items || []}
        rowKey="id"
        pagination={{
          page: queryParams.page || 1,
          pagesCount: settlements?.total_pages || 1,
          onChange: (page) => setQueryParams((prevState) => ({ ...prevState, page }))
        }}
        sorter={sorter}
        onSortChange={(sorter) => {
          setSorter(sorter);
          setQueryParams({
            ...queryParams,
            sortMethod: `${sorter.by}_${sorter.direction.toUpperCase()}`
          });
        }}
      />
    </div>
  );
};

export default FinancesPayments;
