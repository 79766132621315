// typ danych wejściowych

import React, { useEffect, useState, FC, Dispatch, SetStateAction } from 'react';
import { Plus, Dash } from 'react-bootstrap-icons';
import classNames from 'classnames';

import { Loader } from 'components/controls';

import styles from 'theme/components/controls/Counter/Counter.module.scss';

// typ danych wejściowych
interface IProps {
  value: number;
  onChange: (value: number) => void;
  onChangeValue?: Dispatch<SetStateAction<number>>;
  onDecrease: () => void;
  onIncrease: () => void;
  disabled?: boolean;
  className?: string;
  fullWidth?: boolean;
}

const Counter: FC<IProps> = ({
  value,
  onChange,
  onChangeValue,
  onDecrease,
  onIncrease,
  disabled,
  className,
  fullWidth
}) => {
  // aktualna wartość
  const [currentValue, setCurrentValue] = useState(value.toString());

  // nadpisanie aktualnej wartości przy zmianie wartości z props'ów
  useEffect(() => {
    if (!disabled) {
      setCurrentValue(value.toString());
      onChangeValue?.(value);
    }
  }, [value, disabled]);

  // funkcja walidująca wprowadzoną ręcznie ilość
  const manualUpdateQuantity = (newValue: string) => {
    const newValueNumber = parseInt(newValue);

    isNaN(newValueNumber)
      ? setCurrentValue(value.toString())
      : (setCurrentValue(newValueNumber.toString()), onChange(newValueNumber));
  };

  return (
    <div
      className={classNames(
        styles.wrapperComponent,
        className,
        { [styles.fullWidth]: !!fullWidth },
        'StylePath-Components-Controls-Counter'
      )}>
      <button
        className={styles.button}
        type="button"
        onClick={onDecrease}
        disabled={disabled || value <= 0}>
        <Dash />
      </button>
      <input
        className={styles.input}
        value={currentValue}
        onChange={(event) => {
          setCurrentValue(event.target.value);
          onChangeValue?.(parseInt(event.target.value) || 0);
        }}
        onBlur={() => manualUpdateQuantity(currentValue)}
        onKeyUp={(event) => event.key === 'Enter' && manualUpdateQuantity(currentValue)}
        disabled={disabled}
        onClick={(event) => event.currentTarget.focus()}
      />
      {disabled && (
        <div className={styles.loaderWrapper}>
          <Loader />
        </div>
      )}

      <button className={styles.button} type="button" onClick={onIncrease} disabled={disabled}>
        <Plus />
      </button>
    </div>
  );
};

export default Counter;
