import React, { FC, useEffect, useState } from 'react';

import {
  usePostProductQuantityDecrement,
  usePostProductQuantityIncrement,
  usePostProductQuantity
} from 'api';
import { IPosition } from 'api/types';

import { Counter } from 'components/controls';

import styles from 'theme/pages/Product/components/SizingTable/SizingTable.module.scss';

// typ danych wejściowych
interface IProps {
  productId: number;
  unitId: number;
  isNoSelected: boolean;
  onChangeQty: (position: IPosition) => void;
  fullWidth?: boolean;
}

const SizingTableQty: FC<IProps> = ({
  productId,
  unitId,
  isNoSelected,
  onChangeQty,
  fullWidth
}) => {
  // ilość produktów
  const [quantity, setQuantity] = useState(0);

  useEffect(() => {
    if (isNoSelected) {
      setQuantity(0);
    }
  }, [isNoSelected]);

  // pobranie informacji o zmniejsonej ilości
  const { mutate: decrementQuantity, isLoading: isDecrementQuantityLoading } =
    usePostProductQuantityDecrement(productId, {
      onSuccess: (data) => {
        setQuantity(data.data.value);
        onChangeQty({
          product_id: productId,
          quantity: data.data.value,
          unit_id: unitId
        });
      }
    });

  // pobranie informacji o zwiększonej ilości
  const { mutate: incrementQuantity, isLoading: isIncrementQuantityLoading } =
    usePostProductQuantityIncrement(productId, {
      onSuccess: (data) => {
        setQuantity(data.data.value);
        onChangeQty({
          product_id: productId,
          quantity: data.data.value,
          unit_id: unitId
        });
      }
    });

  // pobranie informacji o zwiększonej ilości
  const { mutate: updateQuantity, isLoading: isSetQuantityLoading } = usePostProductQuantity(
    productId,
    {
      onSuccess: (data) => {
        setQuantity(data.data.value);
        onChangeQty({
          product_id: productId,
          quantity: data.data.value,
          unit_id: unitId
        });
      },
      onError: (data) => {
        setQuantity(parseInt(data.error_fields?.[0].suggestedValue || '0') || 0);
      }
    }
  );

  return (
    <div className={styles.counter}>
      <Counter
        onChange={(value) => updateQuantity({ value, unit_id: unitId })}
        onDecrease={() => decrementQuantity({ value: quantity, unit_id: unitId })}
        onIncrease={() => incrementQuantity({ value: quantity, unit_id: unitId })}
        onChangeValue={setQuantity}
        value={isNoSelected ? 0 : quantity}
        disabled={isDecrementQuantityLoading || isIncrementQuantityLoading || isSetQuantityLoading}
        fullWidth={fullWidth}
      />
    </div>
  );
};

export default SizingTableQty;
