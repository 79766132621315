// sekcja demo na stronie głównej

import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetCmsSectionArticle, useGetCmsSectionArticles } from 'api';

import { HtmlBlockMultipleArticle, HtmlBlockSingleArticle } from './components';

import styles from 'theme/components/containers/HtmlBlock/HtmlBlock.module.scss';

interface IProps {
  sectionId: string;
  articleId?: string;
  withBreadCrumbs?: boolean;
  title?: string;
}

export const HtmlBlock: FC<IProps> = ({ sectionId, articleId, withBreadCrumbs, title }) => {
  const { t } = useTranslation();

  // pobranie bloku html
  const { data: singleArticleData, refetch: refetchSingleData } = useGetCmsSectionArticle(
    sectionId,
    articleId || '',
    {
      enabled: false
    }
  );

  // pobranie bloku html
  const { data: multipleArticleData, refetch: refetchMultipleData } = useGetCmsSectionArticles(
    sectionId,
    { fetchArticleFields: true },
    {
      enabled: false
    }
  );

  useEffect(() => {
    if (articleId) {
      refetchSingleData();

      return;
    }

    refetchMultipleData();
  }, [articleId, sectionId]);

  return (
    <>
      {(singleArticleData?.article_fields?.length || multipleArticleData?.items?.length) && title && (
        <div className={styles.title}>{t(title)}</div>
      )}
      {articleId ? (
        <HtmlBlockSingleArticle content={singleArticleData} withBreadCrumbs={withBreadCrumbs} />
      ) : (
        <HtmlBlockMultipleArticle content={multipleArticleData} />
      )}
    </>
  );
};

export default HtmlBlock;
