// box z cenami, jedostkami itp

import React, { FC, Dispatch, SetStateAction, useCallback } from 'react';
import classnames from 'classnames';

import { useSelector } from 'store';
import { useRWD } from 'hooks';

import { IProduct, IPosition, IUnit } from 'api/types';
import { AddToCartButton, AddToShoppingListButton } from 'components/containers';

import styles from 'theme/pages/Product/components/Prices/Prices.module.scss';

// typ danych wejściowych
interface IProps {
  product: IProduct;
  positions: IPosition[];
  setPositions: Dispatch<SetStateAction<IPosition[]>>;
  unit?: IUnit;
}

const Prices: FC<IProps> = ({ product, positions, setPositions, unit }) => {
  const { profile } = useSelector((state) => state.auth);
  const { isMobile } = useRWD();

  const isOpenProfile = profile?.role === 'ROLE_OPEN_PROFILE';

  // funkcja renderująca przycisk dodawania produktu do listy zakupowej
  const renderAddToShoppingListButton = useCallback(
    () => (
      <div className={classnames(styles.shoppingListButtonWrapper)}>
        {profile?.role !== 'ROLE_OPEN_PROFILE' && product && (
          <AddToShoppingListButton
            product={product}
            unit={unit}
            quantity={1}
            isShoppingList={false}
            isButton
          />
        )}
      </div>
    ),
    [profile]
  );

  return (
    <div
      className={classnames(styles.wrapperComponent, 'StylePath-Pages-Product-components-Prices')}>
      <div className={styles.mainContent}>
        <div className={styles.actionsWrapper}>
          <AddToCartButton
            positions={positions}
            clearPositions={() => setPositions([])}
            large
            disabled={isOpenProfile || !positions.length}
            simple
          />
          {/* ukrycie dodawania do ulubionych */}
          {/* {!isMobile && renderAddToShoppingListButton()} */}
        </div>
      </div>
    </div>
  );
};

export default Prices;
