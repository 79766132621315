import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';

import { useNotifications } from 'hooks';
import { useGetOrderExportImages } from 'api';
import { IOrderVmp } from 'api/types';
import { Loader } from 'components/controls';

import styles from 'theme/pages/Order/components/OrderSummary/OrderSummary.module.scss';

interface IProps {
  orderData: IOrderVmp;
}

const DownloadSection: FC<IProps> = ({ orderData }) => {
  const { t } = useTranslation();
  const { showErrorMessage } = useNotifications();

  // loader
  const [isLoading, setIsLoading] = useState(false);

  const { refetch: downloadImages } = useGetOrderExportImages(orderData.id, {
    enabled: false,
    onSuccess: (data) => {
      fetch(data.url)
        .then((resp) => resp.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;

          a.download = data.file_name;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);

          setIsLoading(false);
        })
        .catch(() => showErrorMessage(t('Błąd pobierania pliku')));
    },

    onError: () => {
      setIsLoading(false);
    }
  });

  const handleClick = () => {
    setIsLoading(true);
    downloadImages();
  };

  return (
    <div className={styles.downloadSection}>
      {/* <button>
        <Trans>Faktura</Trans>
      </button>
      <button>
        <Trans>Specyfikacja</Trans>
      </button> */}
      <button disabled={isLoading} onClick={() => handleClick()}>
        <Trans>Zdjęcia</Trans>
        {isLoading && <Loader />}
      </button>
      {/* <button>
        <Trans>EAN</Trans>
      </button> */}
    </div>
  );
};

export default DownloadSection;
