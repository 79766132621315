// widok pozycji w wersji default

import React from 'react';
import { Trans } from 'react-i18next';
import classnames from 'classnames';

import { IColumn } from 'components/controls/Table';
import { ICartPositionListItem } from 'api/types';
import { Counter, Link } from 'components/controls';

import { TrashIcon } from 'assets/icons';

import styles from 'theme/components/containers/CartPositions/CartPositions.module.scss';

export const getEditColumns = ({
  handleChangeQuantity,
  updatingPositionIds,
  setItemsToRemove,
  isCartPositionsRefetching,
  isCart,
  isMobile
}: {
  handleChangeQuantity: (
    position: ICartPositionListItem,
    newValue: number | null,
    isIncrement?: boolean
  ) => void;
  checkedItemIds: number[];
  setCheckedItemIds: (items: number[]) => void;
  updatingPositionIds: number[];
  setItemsToRemove: (items: ICartPositionListItem[]) => void;
  isCartPositionsRefetching?: boolean;
  isMobile: boolean;
  isCart?: boolean;
}): IColumn<ICartPositionListItem>[] => [
  {
    title: '',
    key: 'product-image',
    align: 'left',
    renderCell: (item) => (
      <div className={classnames(styles.productThumb, { [styles.isCart]: !!isCart })}>
        <Link to={`/${item.url_link}`}>
          <img src={item.image[0]?.thumb} alt={item.name} />
        </Link>
      </div>
    ),
    width: 78
  },
  {
    title: '',
    dataIndex: 'name',
    align: 'center',
    renderCell: (item) => (
      <div className={styles.productInfo}>
        <div>
          <Link to={`/${item.url_link}`}>
            <span className={styles.productName}>{item.name}</span>
          </Link>
          <span className={styles.productSize}>
            <Trans>Rozmiar</Trans>: <span>{item.size}</span>
          </span>
          {item.color && (
            <span className={classnames(styles.productSize, styles.productColor)}>
              <Trans>Kolor</Trans>: <span>{item.color}</span>
            </span>
          )}
        </div>
        <div className={styles.quantityCellWrapper}>
          <Counter
            onChange={(value) => handleChangeQuantity(item, value)}
            onIncrease={() => handleChangeQuantity(item, null, true)}
            onDecrease={() => handleChangeQuantity(item, null, false)}
            value={item.quantity}
            disabled={!!updatingPositionIds.length || isCartPositionsRefetching}
          />
        </div>
      </div>
    )
  },
  {
    title: '',
    dataIndex: 'total_price_net',
    align: 'center',
    renderCell: (item) => (
      <div
        className={classnames(
          styles.productTotalPrice,
          item.total_old_price_net_formatted && styles.discount,
          { [styles.isCart]: !!isCart }
        )}>
        <div className={styles.actionsWrapper}>
          <TrashIcon onClick={() => setItemsToRemove([item])} />
        </div>
        <div className={classnames(styles.productPriceWrapper, { [styles.isCart]: !!isCart })}>
          {isCart && !isMobile && (
            <>
              <span className={styles.priceLabel}>
                <Trans>Wartość</Trans>:&nbsp;
              </span>
            </>
          )}

          {item.total_old_price_net_formatted && (
            <div className={styles.oldPrice}>
              {item.total_old_price_net_formatted.replace('.', ',')} {item.currency}{' '}
              <Trans>netto</Trans>
            </div>
          )}
          <div className={styles.newPrice}>
            &nbsp;{item.total_price_net_formatted.replace('.', ',')} {item.currency}{' '}
            {!isMobile && <Trans>netto</Trans>}
          </div>
        </div>
      </div>
    )
  }
];
