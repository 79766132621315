// Hook odpowiedzialny za pobranie profilu

import axios from 'api/axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { ICommandResponseError as IError, IAgreementListItem } from 'api/types';

// typ zwracanych danych
export interface IResponse {
  id: number;
  change_password: boolean;
  consents_required: boolean;
  first_name: string;
  last_name: string;
  email: string;
  overloged: false;
  role: 'ROLE_USER_MAIN' | 'ROLE_USER' | 'ROLE_TRADER' | 'ROLE_ADMIN' | 'ROLE_OPEN_PROFILE';
  sale_supervisor: {
    name: string;
    phone: string;
    email: string;
  };
  agreements: IAgreementListItem[];
  customer: {
    name: string;
    first_name: string;
    last_name: string;
    address: {
      street: string;
      postal_code: string;
      city: string;
      building: string;
      apartment: string;
      country: string;
    };
    nip: string;
    email: string;
    phone?: string;
  };
  delivery_address: {
    id: number;
    name: string;
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    address: {
      street: string;
      postal_code: string;
      city: string;
      building: string;
      apartment: string;
      country: string;
    };
  };
  contact: {
    first_name: string;
    last_name: string;
    phone: string;
    email: string;
  };
  email_to_contact_owner_store: string;
}

const getUserProfile = (): Promise<IResponse> => axios.get('/users/profile');

export const useGetUserProfile = (options?: UseQueryOptions<IResponse, IError>) =>
  useQuery<IResponse, IError>('profile', getUserProfile, options);
