// strona Moje konto

import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { reduxActions, useDispatch, useSelector } from 'store';
import { ChangePasswordForm, UserForm } from 'components/containers';

import styles from 'theme/pages/MyAccount/MyAccount.module.scss';

const DashboardMyAccount = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state.auth);

  // ustawienie breadcrumbs'ów na starcie strony
  useEffect(() => {
    dispatch(
      reduxActions.setBreadcrumbs([
        { name: t('Moje konto'), path: '/dashboard' },
        { name: t('Moje dane') }
      ])
    );
  }, []);

  return (
    <div className={classnames(styles.wrapperComponent, 'StylePath-Pages-MyAccount')}>
      <div className={styles.contentWrapper}>
        <div className={styles.content}>
          {profile && <UserForm />}
          <div className={styles.changePassword}>
            <div className={styles.changePasswordTitle}>
              <Trans>Hasło</Trans>
            </div>
            <ChangePasswordForm vertical />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardMyAccount;
