// slider z produktami kupionymi przez innych

import React, { FC } from 'react';
import classnames from 'classnames';
import { Trans } from 'react-i18next';

import { ProductSlider } from 'components/containers';
import { useGetProductsBoughtByOthers } from 'api';

import styles from 'theme/pages/Product/components/OthersAlsoBought/OthersAlsoBought.module.scss';

// typ danych wejściowych
interface IProps {
  productId: number;
}

const OthersAlsoBought: FC<IProps> = ({ productId }) => {
  // pobranie listy produktów oglądanych prze3z innnych
  const { data: productsBoughtByOthersData } = useGetProductsBoughtByOthers(productId, {
    page: 1,
    limit: 999
  });

  if (!productsBoughtByOthersData?.total_count) {
    return null;
  }

  return (
    <div
      className={classnames(
        styles.wrapperComponent,
        { [styles.onlySlider]: true },
        'StylePath-Pages-Home-components-OthersAlsoBought'
      )}>
      <h2 className={styles.title}>
        <Trans>Powiązane produkty</Trans>
      </h2>
      <div className={styles.content}>
        <ProductSlider products={productsBoughtByOthersData?.items || []} />
      </div>
    </div>
  );
};

export default OthersAlsoBought;
