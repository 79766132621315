// główny layout - górna belka nagłówka

import React from 'react';
import classnames from 'classnames';

import { useGetCmsSectionArticle } from 'api';

import styles from 'theme/components/layouts/MainLayout/components/HeaderTopBar/HeaderTopBar.module.scss';

const HeaderTopBar = () => {
  // pobranie bloku html
  const { data: topBarData } = useGetCmsSectionArticle('TOP_BAR', 'NEWSLETTER_INFO');

  if (!topBarData) {
    return null;
  }

  return (
    <div
      id="top-bar"
      className={classnames(
        styles.componentWrapper,
        'StylePath-Components-Layouts-MainLayout-Components-HeaderTopBar'
      )}
      style={{ backgroundColor: topBarData.article_fields?.[1].value }}>
      <div dangerouslySetInnerHTML={{ __html: topBarData.article_fields?.[0].value }} />
    </div>
  );
};

export default HeaderTopBar;
